import React from 'react'
import './ContactHero.scss'

export class ContactHero extends React.Component {
  render() {
    return (
      <div
        className="contact-hero"
        style={{ backgroundImage: 'url(/contactbg.jpg)' }}
      >
        <div className="overlay" />
        <div className="inner-wrap">
          <div className="text">
            <h1>Contact Us</h1>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactHero
