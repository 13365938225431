import React from 'react'
import { Link } from 'gatsby'
import './ContactContent.scss'
import CallToActionBanner from '../../CallToActionBanner/CallToActionBanner'
import { FaPhone, FaEnvelope, FaCheckCircle } from 'react-icons/fa'
const axios = require('axios')

export class ContactContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideForm: false,
    }
  }

  showThankYou() {
    this.setState({ hideForm: true })
  }

  onchangeText(val) {
    var OriginalValue = document.getElementById('name').value
    document.getElementById('first_name').value = OriginalValue.substr(
      0,
      OriginalValue.indexOf(' ')
    )
    document.getElementById('last_name').value = OriginalValue.substr(
      OriginalValue.indexOf(' ') + 1
    )
  }

  submitForm(ev) {}

  renderFormSuccess() {
    let el
    if (this.state.hideForm) {
      el = (
        <div className="thanks-wrap">
          <div className="thanks-text">
            <div>
              Thanks for the message! We'll be in touch within the next day or
              so.
            </div>
            <div className="thanks-icon">
              <FaCheckCircle />
            </div>
          </div>
        </div>
      )
    } else {
    }
    return el
  }

  renderForm() {
    let el
    el = (
      <div>
        <form
          onSubmit={this.submitForm.bind(this)}
          action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
          method="POST"
          id="CONTACT US FORM"
          noValidate
        >
          <input type="hidden" name="oid" value="00D4T000000FZaP" />
          <input
            type="hidden"
            name="retURL"
            value="http://rackner.com?thankyou=contact"
          />
          <input type="hidden" name="u" value="1" />
          <input type="hidden" name="f" value="1" />
          <input type="hidden" name="s" />
          <input type="hidden" name="c" value="0" />
          <input type="hidden" name="m" value="0" />
          <input type="hidden" name="act" value="sub" />
          <input type="hidden" name="v" value="2" />
          <input
            type="hidden"
            id="first_name"
            maxLength="40"
            name="first_name"
            size="20"
          />
          <input
            type="hidden"
            id="last_name"
            maxLength="80"
            name="last_name"
            size="20"
          />
          <div className="_form-content">
            <div className="_form_element _x70061606 _full_width ">
              <div className="_field-wrapper">
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={this.onchangeText}
                  placeholder="Full Name"
                />
              </div>
            </div>
            <div className="_form_element _x28370249 _full_width ">
              <div className="_field-wrapper">
                <input
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  required
                />
              </div>
            </div>
            <div className="_form_element _field1 _full_width ">
              <div className="_field-wrapper">
                <input type="text" name="company" placeholder="Company" />
              </div>
            </div>
            <div className="_form_element _field2 _full_width ">
              <div className="_field-wrapper">
                <textarea name="description" placeholder="Description" />
              </div>
            </div>
            <div className="_button-wrapper _full_width">
              <button id="_form_1_submit" className="form-button" type="submit">
                Submit
              </button>
            </div>
            <div className="_clear-element" />
          </div>
          <div
            className="_form-thank-you"
            style={{
              display: 'none',
            }}
          />
        </form>
      </div>
    )
    return el
  }

  render() {
    return (
      <div className="contact-content">
        <div className="contact-us-card hide-mobile">
          {this.renderForm()}
          {this.renderFormSuccess()}
        </div>
        <div className="split light subheader-block">
          <div className="text-wrap">
            <div className="inner">
              Send us a message and we'll be in touch within 1-2 business days{' '}
              <br />
              <Link to="/quote">
                <button className="btn-raised-light-blue-inverse">
                  Need a quote instead?
                </button>
              </Link>
            </div>
          </div>
          <div className="contact-us-card hide-desktop">
            {this.renderForm()}
            {this.renderFormSuccess()}
          </div>
        </div>
        <section className="padding-large">
          <div className="row">
            <div className="col-sm-12 ">
              <div
                className="location-card"
                style={{ backgroundImage: 'url("/dcarch.jpg")' }}
              >
                <div className="overlay" />
                <div className="text">
                  <div className="title">DC Metro Area</div>
                  <div className="phone">
                    <FaEnvelope /> contact@rackner.com
                  </div>
                  <div className="description">
                    7338 Baltimore Ave <br />
                    Suite 102 <br />
                    College Park, Maryland 20740
                  </div>
                </div>
              </div>
              <div
                className="location-card"
                style={{ backgroundImage: 'url("/dcarch.jpg")' }}
              >
                <div className="overlay" />
                <div className="text">
                  <div className="title">College Park, MD</div>
                  <div className="phone">
                    <FaEnvelope /> contact@rackner.com
                  </div>
                  <div className="description">
                  7338 Baltimore Avenue, Suite 102
                  <br></br>
                  College Park, Maryland 20740
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CallToActionBanner route={'insights'} linkText={'See Case Studies'} />
      </div>
    )
  }
}

export default ContactContent
