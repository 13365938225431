import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import '../templates/base.scss'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO'
import ContactHero from '../components/Contact/ContactHero/ContactHero'
import ContactContent from '../components/Contact/ContactContent/ContactContent'

class Contact extends React.Component {
  render() {
    const postNode = {
      title: `Contact - ${config.siteTitle}`,
    }

    return (
      <Layout>
        <Helmet>
          <title>{`Contact - ${config.siteTitle}`}</title>
        </Helmet>
        <SEO postNode={postNode} pagePath="contact" customTitle />
        <ContactHero />
        <ContactContent />
      </Layout>
    )
  }
}

export default Contact
